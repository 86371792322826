import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AddressService {
    constructor(private httpClient: HttpClient) { }
    // Danh mục Tỉnh/ Thành phố
    getProvince() {
        return this.httpClient.get<any>(`${environment.apiUrl}/province/get_all`).pipe(
            map(res => {
                return res;
            })
        );
    }

    // Danh mục Quận / Huyện
    getDistrict(id: any) {
        return this.httpClient.get<any>(`${environment.apiUrl}/district/get_all?id=${id}`).pipe(
            map(res => {
                return res;
            })
        );
    }

    // Danh mục Xã / Phường
    getWard(province_id: any, district_id: any) {
        return this.httpClient.get<any>(`${environment.apiUrl}/ward/get_all?province_id=${province_id}&district_id=${district_id}`).pipe(
            map(res => {
                return res;
            })
        );
    }
    selectedYear() {
        return this.httpClient.get<any>(`${environment.apiUrl}/year/selected_year`)
            .pipe(map(res => {
                return res;
            }));
    }
}
