import { EventEmitter, Injectable } from "@angular/core";

@Injectable()
export class UtilityService {
  onShowConfirm = new EventEmitter<any>();
  onShowConfirmYesNoCancel = new EventEmitter<any>();
  onShowMessage = new EventEmitter<any>();

  onSortColumn = new EventEmitter<any>();
  onSortColumnCompleted = new EventEmitter<any>();

  onFilterColumn = new EventEmitter<any>();

  showConfirm(message: string, callback: Function) {
    this.onShowConfirm.emit({ message, callback });
  }

  showConfirmYesNoCancel(message: string, callback: Function) {
    this.onShowConfirmYesNoCancel.emit({ message, callback });
  }

  showMessage(message: string) {
    this.onShowConfirm.emit({ message });
  }

  getReverseFullname(fullname: string): string {
    let rs = "";
    let s = (fullname || "").split(" ").filter((p) => p);
    if (s && s.length > 0) {
      let firstName = s.pop();
      rs = firstName + " " + s.join(" ");
    }
    return rs;
  }

  setReverseFullname(
    data: any[],
    fieldName: string = "fullname",
    reverseFieldName: string = "reverseFullname"
  ) {
    if (data) {
      data.forEach((e) => {
        e[reverseFieldName] = this.getReverseFullname(e[fieldName]);
      });
    }
  }

  getDateNow() {
    const date = new Date();
    const mm = date.getMonth() + 1;
    const dd = date.getDate();
    const yyyy = date.getFullYear();
    const valueDate = yyyy + "-" + (mm < 10 ? "0" + mm : mm) + "-" + dd;
    return valueDate;
  }

  getDaysInMonth(month: number, year: number) {
    return new Date(year, month, 0).getDate();
  }

  getWeekDay(date) {
    const weekdays = new Array("CN", "T2", "T3", "T4", "T5", "T6", "T7");
    const day = date.getDay();
    return weekdays[day];
  }

  getDays(date) {
    var result = new Date(date);
    result.setDate(result.getDate());
    const daysInMonth = this.getWeekDay(result);
    return [daysInMonth, result];
  }

  addDays(date, cal, days) {
    if (cal === "+") {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      const daysInMonth = this.getWeekDay(result);
      return [daysInMonth, result];
    } else {
      var result = new Date(date);
      result.setDate(result.getDate() - days);
      const daysInMonth = this.getWeekDay(result);
      return [daysInMonth, result];
    }
  }

  findDaysOfWeek(object, value) {
    return Object.keys(object).filter((key) => object[key] === value);
  }

  sortFistName(studentData) {
    const array = studentData;
    if (array) {
      array.sort((a, b) => {
        var str1;
        var str2;
        if (a.name) {
          str1 = a.name;
          str1 = str1.split(' ');
          str1 = str1.reverse();
          str1 = str1.toString();
          str1 = str1.toUpperCase();

          str2 = b.name;
          str2 = str2.split(' ');
          str2 = str2.reverse();
          str2 = str2.toString();
          str2 = str2.toUpperCase();
        } else {
          str1 = a.lastname + ' ' + a.firstname;
          str1 = str1.split(' ');
          str1 = str1.reverse();
          str1 = str1.toString();
          str1 = str1.toUpperCase();

          str2 = b.lastname + ' ' + b.firstname;
          str2 = str2.split(' ');
          str2 = str2.reverse();
          str2 = str2.toString();
          str2 = str2.toUpperCase();
        }


        return this.CharCompare(str1, str2, 0);
      });
      return array;
    }
  }

  //sort ten
  alphabetsFather = ['A', 'À', 'Ả', 'Ã', 'Á', 'Ạ',
    'Ă', 'Ằ', 'Ẳ', 'Ẵ', 'Ắ', 'Ặ',
    'Â', 'Ầ', 'Ẩ', 'Ẫ', 'Ấ', 'Ậ',
    'B', 'C', 'D', 'Đ',
    'E', 'È', 'Ẻ', 'Ẽ', 'É', 'Ẹ',
    'Ê', 'Ề', 'Ể', 'Ễ', 'Ế', 'Ệ',
    'F', 'G', 'H',
    'I', 'Ì', 'Ỉ', 'Ĩ', 'Í', 'Ị',
    'J', 'K', 'L', 'M', 'N',
    'O', 'Ò', 'Ỏ', 'Õ', 'Ó', 'Ọ',
    'Ô', 'Ồ', 'Ổ', 'Ỗ', 'Ố', 'Ộ',
    'Ơ', 'Ờ', 'Ở', 'Ỡ', 'Ớ', 'Ợ',
    'P', 'Q', 'R', 'S', 'T',
    'U', 'Ù', 'Ủ', 'Ũ', 'Ú', 'Ụ',
    'Ư', 'Ừ', 'Ử', 'Ữ', 'Ứ', 'Ự',
    'V', 'W', 'X',
    'Y', 'Ỳ', 'Ỷ', 'Ỹ', 'Ý', 'Ỵ',
    'Z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ' '];

  public CharCompare(a, b, index) {
    var aChar;
    var bChar;
    var alphabets = this.alphabetsFather;
    if (index == a.length || index == b.length) return 0;
    aChar = alphabets.indexOf(a.toUpperCase().charAt(index));
    bChar = alphabets.indexOf(b.toUpperCase().charAt(index));
    if (aChar != bChar) return aChar - bChar;
    else return this.CharCompare(a, b, index + 1);
  }
}
